<template>
	<div class="about">
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<!-- <div class="indeximg"><img src="../assets/img/about.jpg" alt=""></div> -->
		<!-- <div class="phoneindeximg"><img src="../assets/img/aboutphone.jpg" alt=""></div> -->
		<div class="idea">
			<span class="titimg"><img src="../assets/img/index3-1.png"></span>
			<p class="cont">
				每个班级独享一部品牌直饮水设备，让你喝到的每一口水都无污染、无危害，在安全中品味大自然的甘露。
			</p>
			<p class="cont">每一片菜叶都经过实验室农药残留检测，每一粒米一滴油都来自管控严格的一站式配送平台国家认证高级营养师让每一天营养达标，合理膳食。</p>
			<p class="cont">
				每一位有幸被聘为伟童的教师，她们都必须是因为爱孩子而希望成为幼师，我们看中技能，但不以技能为录用前提，教师第一素养“持久地爱每一个与你没关系却又关乎幸福的孩子”。全体教师均为学前教育相关专业毕业，持证上岗，定期考核，这是一支充满了活力与爱，耐心与坚持的温暖团队，一路陪伴孩子进入小学。
			</p>
		</div>
		<div class="aboutuser">
			<p class="abp1">伟童幼儿园是一所引进台湾生命教育理念的高端连锁幼儿园。我们每一所园都将为350—500名</p>
			<p class="abp2">幼儿提供高品质的国际化学前教育服务，让每一个孩子都享受真正内心自由并且精神富足的童年。</p>
			<p class="abp3">每个园所都拟定规划13个教学班， 并为孩子们构建了绘本故事屋、亲子烘焙坊、尼塔艺术手作室、</p>
			<p class="abp4">科技体验馆等高端部室。 童年只有一次，交付伟童，不负初心、不负光阴。</p>
			<p class="abp5">伟童幼儿园传播台湾生命教育理念，引导幼儿习得感恩之心，善于发现和珍惜生活中的美好</p>
			<p class="abp6">事物，学会处理与自己、与他人、与社会、与自然之间的关系，成为高情商的幸福人。</p>
			<img class="abm1" src="../assets/img/index4-1.png" alt="">
			<img class="abm2" src="../assets/img/index4-2.png" alt="">
			<img class="abm3" src="../assets/img/index4-3.png" alt="">
		</div>
		<div class="contact">联系我们</div>
		<formdata></formdata>
		<footers></footers>
	</div>
</template>

<script>
	import headers from '../components/header2.vue'
	import footers from '../components/footer.vue'
	import formdata from '../components/forsubmit.vue'
	export default {
		components: {
			headers,
			footers,
			formdata
		},
		data() {
			return {
				imglist:[],
				imglist2:[]
			}
		},
		watch: {

		},
		created() {},
		mounted() {
			this.querySortImg()
		},
		methods: {
			querySortImg(){
				let gh = []
				this.$post('querySortImg',{
					sortHead:2,
					sortOrder:0,
					sortFrom:1
				}).then((res)=>{
					if (res&&res.length>0) {
						this.imglist = []
						this.imglist2 = []
						res.forEach((e, m) => {
							let h = e.sortImg.split(',')
							h.forEach((v, b) => {
								if (e.sortSign == 0) {
									this.imglist.push(v)
								} else {
									gh.push(v)
								}
							})
						})
						if (!gh || gh.length <= 0) {
							this.imglist.forEach((f, g) => {
								f = f + '?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						}else{
							this.imglist2 = gh
						}
					} else {
					
					}
				})
				this.$nextTick(()=>{
					
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.phonebannerimgs,
	.phoneindeximg {
		display: none;
	}
	.contact {
		width: 1200px;
		font-size: 20px;
		margin: 30px auto 60px auto;
	
		position: relative;
	}
	
	.contact::after {
		content: '';
		display: block;
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 10%;
		height: 2px;
		background-color: #4a8ea3;
	}
	.about {
		.bannerimg {
			font-size: 0;

			>img {
				width: 100%;
			}
		}

		.indeximg {
			margin-top: 80px;
			font-size: 0;

			>img {
				width: 100%;
			}
		}

		.phoneindeximg,
		.phonebannerimg {
			display: none;
		}

		.idea {
			width: 1200px;
			font-size: 0;
			margin: 80px auto;
			overflow: hidden;

			.titles {
				margin-bottom: 80px;
			}

			.titimg {
				font-size: 0;
				margin: 0 0 60px 0;
				display: inline-block;
				width: 100%;

				>img {
					width: 100%;
				}
			}

			.cont {
				font-size: 18px;
				line-height: 40px;
				text-indent: 36px;
				letter-spacing: 3px;
				color: #524e50;
			}
		}

		.aboutuser {
			width: 1200px;
			font-size: 0;
			margin: 0 auto 80px auto;
			overflow: hidden;
			position: relative;
			padding: 1% 0 0 0;
			box-sizing: border-box;

			.titles {
				margin: 0 0 80px 0;
			}

			>img {
				position: absolute;
				left: 0;
				top: 0;
			}

			>p {
				font-size: 18px;
				letter-spacing: 1px;
				margin: 0 0 25px 0;
				color: #524e50;
			}

			.abp1 {
				margin-left: 23%;
			}

			.abp2 {
				margin-left: 24%;
			}

			.abp3 {
				margin-left: 22%;
			}

			.abp4 {
				margin-left: 15%;
			}

			.abp5 {
				margin-left: 13%;
			}

			.abp6 {
				margin-left: 10%;
			}

			.abm1 {
				top: 2%;
				left: 9%;
			}

			.abm2 {
				top: 44%;
				left: 0px;
			}

			.abm3 {
				left: auto;
				right: 10%;
				top: auto;
				bottom: 2%;
			}

		}

	}

	@media screen and (max-width:900px) {
		.bannerimgs,
		.indeximg {
			display: none;
		}
		.phonebannerimgs,
		.phoneindeximg {
			display: block;
		}
		.phonebannerimgs{
			margin-top: 61px;
		}
		.about {

			.indeximg,
			.bannerimg {
				display: none;
			}

			.phoneindeximg,
			.phonebannerimg {
				display: block;
				font-size: 0;

				>img {
					width: 100%;
				}
			}

			.phonebannerimg {
				margin-top: 48px;
			}

			.idea,
			.aboutuser {
				width: 97%;
				margin: 40px auto;
			}

			.idea {
				.titles {
					margin-bottom: 60px;
				}

				.titimg {
					margin: 0 0 20px 0;

					>img {
						width: 100%;
					}
				}

				.cont {
					font-size: 14px;
					line-height: 25px;
					text-indent: 30px;
					letter-spacing: 1px;
				}
			}

			.aboutuser {
				.titles {
					margin: 0 0 40px 0;
				}

				>img {
					width: 20%;
				}

				>p {
					font-size: 14px;
					letter-spacing: 1px;
					margin: 0 0 5px 0;
					color: #524e50;
				}

				.abp1 {
					margin-left: 30%;
				}

				.abp2 {
					margin-left: 25%;
				}

				.abp3 {
					margin-left: 22%;
				}

				.abp4 {
					margin-left: 7%;
					width: 80%;
				}

				.abp5 {
					margin-left: 3%;
					width: 70%;
				}

				.abp6 {
					margin-left: 1%;
					width: 80%;
				}

				.abm1 {
					top: 2%;
					left: 5%;
				}

				.abm2 {
					top: 26%;
					left: 0px;
				}

				.abm3 {
					left: auto;
					right: 2%;
					top: auto;
					bottom: 10%;
				}

			}
		}
	}
</style>
